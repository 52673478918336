<template>
  <div>
    <!-- Home Hero Section -->
    <section class="home-hero">
      <v-container>
        <v-row
          style="min-height: 70vh"
          class="flex-md-row-reverse"
          justify="center"
          align="center"
        >
          <!-- Text Content Column -->
          <v-col cols="12" md="6">
            <v-container>
              <div>
                <div
                  class="display-2 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>We believe our scaffolding</span>
                </div>
                <div
                  class="display-2 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>can out-perform any</span>
                </div>
                <div
                  class="display-2 font-weight-black white--text text-left mb-3"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>competitor in the area.</span>
                </div>
                <div
                  class="display-1 font-weight-bold white--text text-left white pa-2"
                  style="text-shadow: 2px 2px 2px #000000; border-radius: 8px"
                >
                  <span class="red--text lighten-1"
                    >See what services we provide below.</span
                  >
                </div>
              </div>
            </v-container>
          </v-col>
          <!-- Image Content Column -->
          <v-col justify="center" align="center" cols="12" md="6">
            <v-img
              max-width="75vw"
              contain
              src="path-to-your-image.webp"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Services Section -->
    <section>
      <v-container class="px-12 pt-12">
        <v-row fill-height justify="center" align="center">
          <!-- Engineering Services Column -->
          <v-col cols="12" md="6">
            <div>
              <div
                class="display-1 font-weight-black mb-2 text-left red--text lighten-1"
                style="text-shadow: 2px 2px 3px #000000"
              >
                <span>Engineering Services</span>
              </div>
              <div class="title text-left">
                <span style="color: #d6ebff">
                  From the ground up – we design, fabricate, and erect steel
                  structures, provide on-site civil work, and offer a full
                  spectrum of engineering and maintenance services.
                </span>
              </div>
            </div>
          </v-col>
          <!-- Divider -->
          <v-divider
            class="hidden-sm-and-down"
            style="background-color: white"
            vertical
          />
          <v-divider class="hidden-md-and-up" style="background-color: white" />
          <!-- Equipment Supply Column -->
          <v-col class="pl-md-8" cols="12" md="6">
            <div>
              <div
                class="display-1 font-weight-black text-left mb-2 red--text lighten-1"
                style="text-shadow: 2px 2px 3px #000000"
              >
                <span>Equipment Supply</span>
              </div>
              <div class="title text-left">
                <span style="color: #d6ebff">
                  Quality equipment for top-notch finishes – offering everything
                  from spray painting booths to climate control units, ensuring
                  your project is equipped for excellence.
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row fill-height justify="center" align="center">
          <!-- Consulting Services Column -->
          <v-col cols="12" md="6">
            <div>
              <div
                class="display-1 font-weight-black mb-2 text-left red--text lighten-1"
                style="text-shadow: 2px 2px 3px #000000"
              >
                <span>Consulting Services</span>
              </div>
              <div class="title text-left">
                <span style="color: #d6ebff">
                  Guiding your projects to success – we provide consultancy in
                  facility design, application processes, and training, with a
                  focus on efficiency and safety.
                </span>
              </div>
            </div>
          </v-col>
          <!-- Divider -->
          <v-divider
            class="hidden-sm-and-down"
            style="background-color: white"
            vertical
          />
          <v-divider class="hidden-md-and-up" style="background-color: white" />
          <!-- Welding & Other Services Column -->
          <v-col class="pl-md-8" cols="12" md="6">
            <div>
              <div
                class="display-1 font-weight-black text-left mb-2 red--text lighten-1"
                style="text-shadow: 2px 2px 3px #000000"
              >
                <span>Welding & Other Services</span>
              </div>
              <div class="title text-left">
                <span style="color: #d6ebff">
                  Our expert welding and boiler-making services ensure durable
                  constructions. Protect and enhance your assets with our
                  corrosion control, industrial painting, and specialized
                  insulation services.
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Contact Call-to-Action Section -->
    <section class="about-background" style="min-height: 45vh">
      <v-container class="mt-sm-12">
        <v-row class="pt-12 text-center" justify="center" align="center">
          <v-col cols="auto">
            <div class="white--text mt-8">
              <p class="ma-0 text-h2 font-weight-bold">
                We offer a free scaffolding assessment on site
              </p>
              <p class="mt-2" style="color: #d6ebff">
                Do not hesitate to contact us now!
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-img bottom class="positionleft"></v-img>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style scoped>
.hero {
  display: block;
  box-sizing: border-box;
  height: 500px;
  background-color: #3d99dc;
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1636362006544-22445420703f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.gradient-text {
  background-image: linear-gradient(
    60deg,
    rgb(3, 124, 230),
    rgb(117, 177, 230)
  );
  background-clip: text;
  color: transparent;
}
.about-background {
  /* background: url("https://images.unsplash.com/photo-1504964670878-71b73cec0ce1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1164&q=80"); */
  background-color: red;

  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(85% 56% at 51% 63%);
}
.positionleft {
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  position: absolute;
  background: url("https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/path7471.webp?alt=media&token=28b1465f-64e8-4889-9588-87dc0389cdfa");
  background-size: contain;
}
</style>
