<template>
  <ServicesHero />
</template>

<script>
import ServicesHero from "@/components/ServicesHero";
export default {
  components: {
    ServicesHero,
  },
  metaInfo: {
    title: "Scaffolding Services - Concraft",
    meta: [
      {
        name: "description",
        content:
          "Discover the range of scaffolding services offered by Concraft, ensuring safety, quality, and reliability.",
      },
    ],
  },
};
</script>

<style></style>
