<template>
  <AboutHero />
</template>

<script>
import AboutHero from "@/components/AboutHero";
export default {
  components: {
    AboutHero,
  },

  metaInfo: {
    title: "About Concraft Scaffolding",
    meta: [
      {
        name: "description",
        content:
          "Learn about Concraft Scaffolding, our history, values, and commitment to excellence in the scaffolding industry.",
      },
    ],
  },
};
</script>

<style></style>
