<template>
  <ContactHero />
</template>

<script>
import ContactHero from "@/components/ContactHero";
export default {
  components: {
    ContactHero,
  },
  metaInfo: {
    title: "Contact Concraft Scaffolding",
    meta: [
      {
        name: "description",
        content:
          "Get in touch with Concraft Scaffolding for inquiries, support, or to discuss your scaffolding requirements.",
      },
    ],
  },
};
</script>

<style></style>
