<template>
  <div>
    <section class="home-hero" >
      <v-container>
        <v-row style="min-height: 70vh" justify="center" align="center">
          <v-col cols="12" md="6">
            <div>
              <div
                class="display-2 font-weight-black white--text text-left"
                style="text-shadow: 2px 2px 8px #000000"
              >
                <span>We strive to provide, </span>
              </div>
              <div
                class="display-2 font-weight-black white--text text-left"
                style="text-shadow: 2px 2px 8px #000000"
              >
                <span>only the best scaffolding</span>
              </div>
              <div
                class="display-2 font-weight-black white--text text-left mb-3"
                style="text-shadow: 2px 2px 8px #000000"
              >
                <span>equipment and services</span>
              </div>
              <div
                class="display-1 pa-2 font-weight-bold white--text text-left white"
                style="text-shadow: 2px 2px 2px #000000; border-radius: 6px"
              >
                <span class="red--text lighten-1"
                  >Here you can learn more about Concraft Scaffolding</span
                >
              </div>
            </div>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-img
            class="mx-auto"
              max-width="400px"
              contain
              src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/g3107.webp?alt=media&token=91576674-6ac1-4475-a9a0-5895ac3129a2"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!-- BEE rating -->
    <section>
      <v-container>
        <v-row style="min-height: 60vh" justify="center" align="center">
          <v-col cols="12" md="6">
            <v-container>
              <div>
                <div
                  class="display-2 font-weight-black text-left red--text lighten-1"
                  style="text-shadow: 1px 1px 2px #000000"
                >
                  <span>Concraft </span>
                </div>
                <div
                  class="display-1 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span> has a Level 2 BEE Rating</span>
                </div>

                <div
                  class="headline font-weight-bold white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span style="color: #d6ebff"
                    >We are proudly a 51% black-owned enterprise</span
                  >
                </div>
              </div>
            </v-container>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-img
              contain
              max-width="420px"
              src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/bee2.webp?alt=media&token=1a8392c3-6024-4804-a574-583f114af836"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section
      style="
        background-color: red;

        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
      "
    >
      <v-container>
        <v-row
          style="min-height: 640px"
          class="flex-md-row-reverse"
          justify="center"
          align="center"
        >
          <v-col cols="12" md="6">
              <div>
                <div
                  class="display-2 font-weight-black text-left ml-auto"
                  style="text-shadow: 2px 2px 7px #003003; color: #008ae0"
                >
                  <span>Concraft</span>
                </div>
                <div
                  class="display-2 font-weight-black white--text text-left mb-4"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>employs a total of 120 people</span>
                </div>

                <div
                  class="headline font-weight-bold white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span style="color: #d6ebff"
                    >At the height of our operation stands our staff
                  </span>
                </div>
              </div>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-img
            class="my-4"
              max-height="320px"
              max-width="420px"
              style="border-radius:8px"
              src="@/assets/about1.png"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
      <v-img class="positionleft"></v-img>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style scoped>
@font-face {
  font-family: "BIRDMAN";
  src: local("BIRDMAN"), url(/fonts/Birdman/BIRDMAN_.ttf) format("truetype");
}
.hero {
  display: block;
  box-sizing: border-box;
  height: 500px;
  background-color: #3d99dc;
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1504964670878-71b73cec0ce1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1164&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}

.about-background {
  /* background: url("https://images.unsplash.com/photo-1504964670878-71b73cec0ce1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1164&q=80"); */
  background-color: red;

  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
}
.gradient-text {
  background-image: linear-gradient(
    60deg,
    rgb(3, 124, 230),
    rgb(117, 177, 230)
  );
  background-clip: text;
  color: transparent;
}
.positionleft {
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  position: absolute;
  background: url("https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/path7471.webp?alt=media&token=28b1465f-64e8-4889-9588-87dc0389cdfa");
  background-size: contain;
}
</style>
