<template>
  <v-container>
    <v-row>
      <v-col class="mt-8 mb-8" style="color: white" cols="12">
        <h1 class="text-md-h1 te font-weight-bold mb-2">
          Our <span style="color: #01578e">Product </span> list
        </h1>
        <p>
          Welcome to Concraft Scaffolding's Products Page! As a leading name in
          the industry, we proudly present a diverse range of scaffolding
          solutions tailored to meet your construction and renovation needs.
          From robust and durable platforms to innovative and secure fittings,
          our products are designed with precision and expertise. Dive in to
          explore our offerings and discover how Concraft Scaffolding can
          elevate your next project to new heights.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="product in products" :key="product" md="4">
        <v-card outlined>
          <v-card-title
            class="text-md-h4 text-lg-h3 font-weight-bold"
            style="background-color: #01578e; color: whitesmoke"
            >{{ product.name }}</v-card-title
          >
          <div class="image-container">
            <v-img
              height="280px"
              width="100%"
              :src="require(`@/assets/products/${product.image}`)"
            ></v-img>
            <v-img
              class="hover-image"
              :src="require(`@/assets/products/${product.hoverImage}`)"
              height="280px"
              width="100%"
            ></v-img>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          name: "Birdcage",
          image: "birdcage1.png",
          hoverImage: "birdcage2.png",
        },
        {
          name: "Bridges",
          image: "bridge1.png",
          hoverImage: "bridge2.png",
        },
        {
          name: "Independent",
          image: "independent1.png",
          hoverImage: "independent2.png",
        },
        {
          name: "Mobile",
          image: "mobile1.png",
          hoverImage: "mobile2.png",
        },
        {
          name: "Offsite",
          image: "offsite1.png",
          hoverImage: "offsite2.png",
        },
        {
          name: "Tank",
          image: "tank1.png",
          hoverImage: "tank2.png",
        },
        {
          name: "Tower",
          image: "tower1.png",
          hoverImage: "tower2.png",
        },
      ],
    };
  },
  metaInfo: {
    title: "Concraft Scaffolding Products",
    meta: [
      {
        name: "description",
        content:
          "Browse our range of scaffolding products, ensuring durability, safety, and top-tier quality.",
      },
    ],
  },
};
</script>
<style>
.image-container {
  position: relative;
  height: 280px;
  width: 100%;
  overflow: hidden;
}

.hover-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease; /* 0.3s is the duration of the fade effect */
}

.image-container:hover .hover-image {
  opacity: 1;
}
</style>
