<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1
          class="font-weight-bold mb-5 text-md-h1 mt-8"
          style="color: whitesmoke"
        >
          <span style="color: #01578e">Concraft Scaffolding </span>Capabilities
        </h1>
        <p style="color: whitesmoke">
          Explore Concraft Scaffolding's extensive capabilities, where
          innovation meets expertise. From advanced engineering solutions to
          state-of-the-art electrical installations, our diverse range of
          services ensures that we cater to all your scaffolding needs. Dive in
          to discover how our unparalleled skills and cutting-edge techniques
          set us apart in the industry.
        </p>
      </v-col>
    </v-row>

    <!-- Engineering Section -->
    <v-row>
      <v-col md="6" cols="12" v-for="section in sections" :key="section.title">
        <v-card
          outlined
          class="d-flex flex-column"
          style="height: 100%; background-color: #636369"
        >
          <v-card-title
            class="text-md-h3 font-weight-bold align-center"
            style="background-color: #01578e; color: whitesmoke"
          >
            {{ section.title }}
          </v-card-title>
          <v-card-text class="mt-3" style="color: whitesmoke">
            <ul>
              <li v-for="item in section.items" :key="item">{{ item }}</li>
            </ul>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sections: [
        {
          title: "Engineering",
          items: [
            "Design and Fabrication of Structural Steel Structures, Hoppers, Ducting, Chutes and Buildings",
            "On site Steel Erection and all related Civil work",
            "Sheeting Installation for both new construction and refurbishment projects",
            "Fabrication of both Stainless and Mild Steel Pipe Specials",
            "Tank Repairs",
            "Metal Repairs",
            "Engineering and Maintenance",
            "Insulation and sheeting",
            "High pressure cleaning Mechanical piping",
          ],
        },
        {
          title: "Welding and Other Services",
          items: [
            "Welding and Boiler-making",
            "Corrosion Protection and Thermal Insulation",
            "Large Domestic Painting",
            "Waterproofing",
            "Abrasive Blasting",
            "Industrial Painting",
            "Specialized Corrosion Protection",
            "Epoxy and Polyurethane Floor Coatings",
            "Pipe Field Joint Coatings, Linings, Tape Wrappings and Shrink Sleeve Applications",
            "Scaffolding supply and erection",
          ],
        },
        {
          title: "Equipment Supply",
          items: [
            "Spray painting equipment and Booths",
            "Shot Blasting Equipment and Booths",
            "Painting and Blasting Quality Control Instruments",
            "Ambient Air and Compressed Air Filtration",
            "Fans, Climate Control and Air Conditioning Units",
          ],
        },
        {
          title: "Consulting Services",
          items: [
            "Blasting and Paint Applications",
            "Design and Manufacture of New Facilities",
            "Specialized Ventilation",
            "Fire Approval of Facilities",
            "Operator Training",
          ],
        },
      ],
    };
  },
  metaInfo: {
    title: "Concraft Scaffolding Capabilities",
    meta: [
      {
        name: "description",
        content:
          "Dive into the capabilities of Concraft Scaffolding, showcasing our strengths, innovations, and expertise in the field.",
      },
    ],
  },
};
</script>

<style></style>
