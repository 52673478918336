<template>
  <TeamHero />
</template>

<script>
import TeamHero from "@/components/TeamHero";
export default {
  created() {},
  components: {
    TeamHero,
  },
  metaInfo: {
    title: "Meet the Concraft Scaffolding Team",
    meta: [
      {
        name: "description",
        content:
          "Introducing the dedicated team behind Concraft Scaffolding, driven by expertise and passion.",
      },
    ],
  },
};
</script>

<style></style>
