<template>
  <GalleryHero />
</template>

<script>
import GalleryHero from "@/components/GalleryHero";
export default {
  created() {},
  components: {
    GalleryHero,
  },
  metaInfo: {
    title: "Concraft Scaffolding Gallery",
    meta: [
      {
        name: "description",
        content:
          "Explore our gallery showcasing our top-notch scaffolding projects and the quality of our work.",
      },
    ],
  },
};
</script>

<style></style>
